import { SingletonService } from "./singleton.service";
import { Router, NavigationStart } from "@angular/router";
import { Injectable, ViewChild } from "@angular/core";
import { Observable } from "rxjs"; 
import { HttpClientService } from "./http-client.service";
@Injectable()
export class UserService {
  userList: any;
  constructor(private router: Router, private ss: SingletonService,private httpClient : HttpClientService) {}

  getToken() {
    console.log(localStorage.getItem("token"));
    return localStorage.getItem("token");
  }
  getRole() {
    return this.getTokenPayload()["role"];
  }
  getSessionExpiry() {
    return this.getTokenPayload()["exp"];
  }
  
  getUserId() {
    console.log(this.getTokenPayload()["user"]['user_id']);
    
    return this.getTokenPayload()["user"]['user_id'];
  }

  getUserName() {
    return this.getTokenPayload()["user"]['username'];
  }

  getUserEmail() {
    return this.getTokenPayload()["email_id"];
  }

  getDataFromToken(key) {
    return this.getTokenPayload()[key];
  }

  isDemo() {
    return localStorage.getItem("isDemo") == "true";
  }

  // check if the expiration of token is valid.
  validateSession() {
    if (localStorage.getItem("token")) {
      let timeStamp = this.getDataFromToken("exp") * 1000;
      var diff = parseInt(localStorage.getItem("timeDiff"), 10);
      let now = Date.now();
      let boolean: boolean = timeStamp   > now;
        // console.log(now, timeStamp+diff, timeStamp,  diff);
        // console.log(new Date(timeStamp+diff));
        console.log("validate : "+boolean);
      this.ss.loggedIn$.next(boolean);
      return boolean;
    } else {
      this.ss.loggedIn$.next(false);
      return false;
    }
  }

  // remove the user meta data in the local storage
  resetSession() { 
    localStorage.removeItem("token"); 
    //console.log(this.getRole());
  }

  public reloadOnLogout;

  logout(
    reload: boolean = true,
    isExamSubmittedSuccessfullyEventTriggered = false,
    studentNameString = null
  ) {
    // let loginEndPoint;
    // let role = this.getRole();
    // loginEndPoint = role === "student" ? "/login/student" : "/login/staff";
    // emit to the subscribers of isLoggedIn
    this.ss.loggedIn$.next(false);
    // reset the session
    this.resetSession();

    if (reload) {
      setTimeout(function() {
        window.location.reload();
      });
    }
  }

  // get the payload from JWT   -- header.payload.signature
  getTokenPayload() {
    if (localStorage.getItem("token")) {
      // get the part of the token which can be base 64 decoded
      let payload = localStorage.getItem("token").split(".")[1];
      // decode the payload
      let decoded = this.b64DecodeUnicode(payload);
      console.log(decoded);
      
      let parsed = JSON.parse(decoded);
      return parsed;
    } else {
      return false;
    }
  }  
 

  // method to base64 decode the token to get decoded text ( should work with unicode text also )
  b64DecodeUnicode(str) {
    return decodeURIComponent(
      atob(str)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  b64EncodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(<any>"0x" + p1);
      })
    );
  }
}