import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'lodash'
})
export class LodashPipe implements PipeTransform {
    transform(value: any, ...args: Array<any>): any {

        // get the method which needs to be invoked from the lodash pipe parameters passed
        var method = args.shift();
        
        // return the value after invoking the lodash method with the necessary arguments
        return _[method](value, ...args);
        
    }

}
