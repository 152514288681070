import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SingletonService } from 'src/app/services/singleton.service';
import { Router } from '@angular/router';
import { HttpClientService } from 'src/app/services/http-client.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  fb:FormBuilder = new FormBuilder();

  loginForm : FormGroup;

  constructor( 
    private ss: SingletonService,
    private httpClient: HttpClientService, 
    private router: Router,
    private user:UserService
    ) {
        this.loginForm = this.fb.group({
          email:["",Validators.email],
          password:["",Validators.required],
          // captcha:["",Validators.required]
        });
    }

  ngOnInit() { 
    if(this.user.validateSession()){
      // this.ss.loggedIn$.next(true);
      this.router.navigate(["/dashboard"])
    } 
    // this.getCaptcha()   
  }

  captchaData;


  onLogin(e:Event){
    if(this.loginForm.valid){
      this.httpClient.request('post',this.ss.login,"",this.loginForm.value).subscribe(res=>{
        // console.log(res);
        console.log(res.body)
        if(res.body){ 
          localStorage.setItem('token',res.body.token);
          window.location.reload();
          this.ss.statusMessage.showStatusMessage(true, "You have logged in Successfully");
          this.router.navigate(['/dashboard']);
        }else if (res.error.text == 'fail'){
          console.log(res.error)
          this.ss.statusMessage.showStatusMessage(false, "Invalid Credentials")

          // if(res.error === "password"){
          //   this.ss.statusMessage.showStatusMessage(false, "The password entered is wrong")
          // }else if(res.error === "email"){
          //   this.ss.statusMessage.showStatusMessage(false, "The user name does not exist")
          // }
        }else{
          this.ss.statusMessage.showStatusMessage(false,"Something went wrong")
        }
      })
    }
  }

}
