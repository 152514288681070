import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './view-components/login/login.component';
import { DashboardComponent } from './view-components/dashboard/dashboard.component';
import { ProductsComponent } from './view-components/products/products.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ReportsComponent } from './view-components/reports/reports.component';

/* data: {
    breadcrumb: { // when breadcrumb data is an object
        queryParamKey: '' // possible values are string with queryparam name (eg: 'cpu_id') or array of query params (eg: ['edge_node_id','cpu_id']) or string with value 'all' . based on this all queryparams from current route will be available on the breadcrumb link,
            , addQueryParamInUrl: true // if this key is set to true then only the query params are added in the link of the breadcrumb segment
    }
} */

const routes: Routes = [
    { path: 'dashboard',canActivate:[AuthGuardService], data: { breadcrumb: 'Dashboard' }, component: DashboardComponent },
    { path: 'products',canActivate:[AuthGuardService], data: { breadcrumb: 'Products' }, component: ProductsComponent },
    { path: 'reports',canActivate:[AuthGuardService], data: { breadcrumb: 'Reports' }, component: ReportsComponent },
    { path: 'login', data: { breadcrumb: 'Login' }, component: LoginComponent },
    // { path: 'reports', data: { breadcrumb: 'Reports' }, component: ReportsComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
// http://localhost:4200/logical-view/edge-node/edge-node-details/cpu-details?ip=192.168.26.16&edge_node_id=192.168.26.16&view=Frames&cpu_id=1&&gpu_id=1