import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SidebarComponent } from '../layout/sidebar/sidebar.component'
import { LoaderComponent } from '../components/loader/loader.component';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';
import { StatusMessageComponent } from '../components/status-message/status-message.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { UAParser } from 'ua-parser-js';
import {uaParser} from './../interfaces/ua-parser';
import { ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';

@Injectable({
  providedIn: 'root'
})
export class SingletonService {


  isMenuOpenSubject: Subject<boolean> = new Subject();
  setactive: Subject<number> = new Subject();
  knowFrames: Subject<string> = new Subject();

  loader: LoaderComponent;

  progressBar: ProgressBarComponent;

  statusMessage: StatusMessageComponent;

  baseUrl = "https://raise.atai.ai/api/";
  //  baseUrl = "http://10.60.62.54:3000/api/";
  // baseUrl = "http://10.60.62.61:3000/api/";
  // baseUrl = "http://10.60.69.50:3000/api/";
  // baseUrl = "http://10.60.62.54:5555/api/";
  // baseUrl = "http://192.168.33.173:3000/api/";
  // baseUrl = "http://13.233.72.116/api/";

  login = 'login';
  downloadExcel= 'download-excel';
  addRequest = 'add-request';
  getRequests = 'get-request-list';
  getProducts = 'get-products-list';
  getReimbursement = 'get-reimbursement-list';
  getProductHistory = 'get-product-history';
  getRejectRequests = 'get-reject-request-list';
  getMoreInfoRequests ='get-more-info-request-list';
  getUsers = 'get-user-list';
  getMoreInfoL2Requests = 'get-more-info-l2-request-list';
  getPreRfqRequests = 'get-pre-rfq-request-list';
  getRfqRequests = 'get-rfq-request-list';
  getL2ApprovalRequests = 'get-l2approval-request-list';
  getOrderPlacedRequests = 'get-order-placed-request-list';
  getOrderReceivedRequests = 'get-order-received-request-list'
  getPushToAssetsRequests = 'get-push-to-assets-request-list'
  getPORequests = 'get-po-request-list';
  moveToStage2 = 'move-to-stage2';
  moveToStage3 = 'move-to-stage3';
  moveToStage4 = 'move-to-stage4';
  moveToStage5 =  'move-to-stage5';
  moveToStage5WithReply = 'move-to-stage5-with-reply';
  moveToStage6 = 'move-to-stage6';
  moveToStage7 = 'move-to-stage7';
  moveToStage8 = 'move-to-stage8';
  moveToStage9 = 'move-to-stage9';
  moveToProducts = 'move-to-products';
  moveToProductsSingle = 'move-to-products-single';
  moveToProductsSingleTravel = 'move-to-products-single-travel';
  moveToStage1ForInfo = 'move-to-stage1-for-info';
  moveToStage1ForReject = 'request-rejected';
  moveToStage1ForL2Reject = 'request-rejected-l2';
  moveToStage1ForMoreInfo = 'request-for-more-info';
  moveToStage4ForMoreInfo = 'request-for-more-info-stage4';
  uploadUpdate = 'upload-update';
  updateUploadCount = 'update-upload-count';
  
  isMac;
  presets: { presetLabel: string; range: { fromDate: Date; toDate: Date; }; }[];
  
  // client_config io_config
  constructor(private _http:HttpClient) { 
    this.checkForMacOs();
  }

  /************ Start BREADCRUMB  ************/
  public breadCrumbMap: Array<any> = [];

  public breadCrumbSubject: Subject<any> = new Subject();

  setBreadCrumbData(key, value, noclick: boolean = false, queryParamsObj: Object = {}) {
    // set the breadcrumb text value as part of the data available for the breadcrumb
    this.breadCrumbMap[key] = value;
    // send the noclick boolean into the data available for the breadcrumb
    this.breadCrumbMap[key + '_noclick'] = noclick;
    // send the query Params if any into the data available for the breadcrumb
    this.breadCrumbMap[key + '_queryParams'] = queryParamsObj;

    this.breadCrumbSubject.next();
  }

  loggedIn$ : Subject<boolean> = new Subject();

  /************** END Bread CRumb implementation ************/
  setActive(userid: number) {
    this.setactive.next(userid)
  }

  getFrames(name: string) {
    this.knowFrames.next(name)
    console.log(this.knowFrames);
  }

  setupPresets() {
    const backDate = numOfDays => {
      // tslint:disable no-shadowed-variable
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date();
    // new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {
        presetLabel: 'Yesterday',
        range: { fromDate: yesterday, toDate: yesterday }
      },
      {
        presetLabel: 'Last 7 Days',
        range: { fromDate: minus7, toDate: today }
      },
      {
        presetLabel: 'Last 30 Days',
        range: { fromDate: minus30, toDate: today }
      },
      {
        presetLabel: 'This Month',
        range: { fromDate: currMonthStart, toDate: today }
      },
      {
        presetLabel: 'Last Month',
        range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      }
    ];
  }
  
public lineChartOptionsDisable: (ChartOptions & { annotation: any }) = {
  responsive: true,
//   legend: {
//     onClick: (e:any,legendItem) =>{
//       var index = legendItem.datasetIndex;
//       console.log(legendItem);
      
//         if(index == 2){

//         }
//         else{

//         }
//         console.log(index);
        
//     }
// },
legend: {
  position: 'top',
  display: true,
  fullWidth: true,
  labels: {
    fontSize: 14,
  }
},
  scales: {
 
    // We use this empty structure as a placeholder for dynamic theming.
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Day Hour(24 Hour)'
      },
      offset: true
    }],
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Images'
      },
        id: 'y-axis-0',
        position: 'left',
      }
    ]
  },
  annotation: {
    annotations: [
      {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: 'March',
        borderColor: 'orange',
        borderWidth: 2,
        label: {
          enabled: true,
          fontColor: 'orange',
          content: 'LineAnno'
        }
      },
    ],
  },
};

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    legend: {
      labels: {
        fontSize: 14,
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Date'
        },
        offset: true
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Images'
        },
          id: 'y-axis-0',
          position: 'left',
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  
  public barChartColors: Color[] = [
    { // red
      backgroundColor: 'rgba(63, 149, 205,1)',
      borderColor: '#3e95cd',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // green
      backgroundColor: 'rgba(125, 104, 171,1)',
      borderColor: '#8e5ea2',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // blue
      backgroundColor: 'rgba(255,99,132,1)',
      borderColor: 'rgba(255,99,132,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // orange
      backgroundColor: 'rgba(43, 106, 146,1)',
      borderColor: '#3cba9f',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(196, 87, 80,1)',
      borderColor: '#c45850',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartColors: Color[] = [
    { // red
      backgroundColor: 'rgba(63, 149, 205,0.2)',
      borderColor: '#3e95cd',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // green
      backgroundColor: 'rgba(125, 104, 171,0.2)',
      borderColor: '#8e5ea2',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // blue
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // orange
      backgroundColor: 'rgba(43, 106, 146,0.2)',
      borderColor: '#3cba9f',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(196, 87, 80,0.2)',
      borderColor: '#c45850',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartColorsLiveData: Color[] = [
   
    { // green
      backgroundColor: 'rgba(125, 104, 171,0.2)',
      borderColor: '#8e5ea2',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // blue
      backgroundColor: 'rgba(255,99,132,0.2)',
      borderColor: 'rgba(255,99,132,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // purple
      backgroundColor: 'rgba(196, 87, 80,0.2)',
      borderColor: '#c45850',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  getDateTime() {

    let date = new Date();

    let hour:any = date.getHours();
    hour = (hour < 10 ? "0" : "") + hour;

    let min:any = date.getMinutes();
    min = (min < 10 ? "0" : "") + min;

    let sec:any = date.getSeconds();
    sec = (sec < 10 ? "0" : "") + sec;

    let year:any = date.getFullYear();

    let month:any = date.getMonth() + 1;
    month = (month < 10 ? "0" : "") + month;

    let day:any = date.getDate();
    day = (day < 10 ? "0" : "") + day;

    return year + "_" + month + "_" + day + "_" + hour + "_" + min;

}
  

  checkForMacOs() {
    let platform = navigator.platform;
    if ( platform === 'MacIntel' || platform === 'MacPPC' ) {
      this.isMac = true;
  }
  }
  
}
