import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild('requestModal') requestModal: ModalPopupComponent;
  @ViewChild('l1StageModal') l1StageModal: ModalPopupComponent;
  @ViewChild('l1StageApproveModal') l1StageApproveModal: ModalPopupComponent;
  @ViewChild('rejectedModal') rejectedModal: ModalPopupComponent;
  @ViewChild('rejectedL2Modal') rejectedL2Modal: ModalPopupComponent;
  @ViewChild('moreInfoModal') moreInfoModal: ModalPopupComponent;
  @ViewChild('moreInfoL2Modal') moreInfoL2Modal: ModalPopupComponent;
  @ViewChild('rejectedCommentModal') rejectedCommentModal: ModalPopupComponent;
  @ViewChild('rejectedL2CommentModal') rejectedL2CommentModal: ModalPopupComponent;
  @ViewChild('moreInfoCommentModal') moreInfoCommentModal: ModalPopupComponent;
  @ViewChild('moreInfoL2CommentModal') moreInfoL2CommentModal: ModalPopupComponent;
  @ViewChild('move2Stage2CommentModal') move2Stage2CommentModal: ModalPopupComponent;
  @ViewChild('move2Stage5ReplyCommentModal') move2Stage5ReplyCommentModal: ModalPopupComponent;
  @ViewChild('move2Stage4CommentModal') move2Stage4CommentModal: ModalPopupComponent;
  @ViewChild('move2Stage5CommentModal') move2Stage5CommentModal: ModalPopupComponent;
  @ViewChild('move2Stage7CommentModal') move2Stage7CommentModal: ModalPopupComponent;
  @ViewChild('move2Stage8CommentModal') move2Stage8CommentModal: ModalPopupComponent;
  @ViewChild('move2Stage9CommentModal') move2Stage9CommentModal: ModalPopupComponent;
  @ViewChild('move2AssetsCommentModal') move2AssetsCommentModal: ModalPopupComponent;
  @ViewChild('preRfqStageModal') preRfqStageModal: ModalPopupComponent;
  @ViewChild('rfqStageModal') rfqStageModal: ModalPopupComponent;
  @ViewChild('l2ApprovalStageModal') l2ApprovalStageModal: ModalPopupComponent;
  @ViewChild('poStageModal') poStageModal: ModalPopupComponent;
  @ViewChild('orderPlacedStageModal') orderPlacedStageModal: ModalPopupComponent;
  @ViewChild('orderReceviedStageModal') orderReceviedStageModal: ModalPopupComponent;
  @ViewChild('pushToAssetsstageModal') pushToAssetsstageModal: ModalPopupComponent;
  @ViewChild('NgFormRequest') NgFormRequest;
  @ViewChild('NgFormReject') NgFormReject;
  @ViewChild('NgFormL2MoreInfo') NgFormL2MoreInfo;
  @ViewChild('NgFormMoreInfo') NgFormMoreInfo;
  @ViewChild('NgFormL1Approve') NgFormL1Approve;
  @ViewChild('NgFormL1Reply') NgFormL1Reply;
  @ViewChild('NgFormL2Reply') NgFormL2Reply;
  @ViewChild('NgFormPreRfq') NgFormPreRfq;
  @ViewChild('NgFormRfq') NgFormRfq;
  @ViewChild('NgFormPO') NgFormPO;
  @ViewChild('NgFormOrderPlaced') NgFormOrderPlaced;
  @ViewChild('NgFormOrderReceived') NgFormOrderReceived;
  @ViewChild('NgFormpushToAssets') NgFormpushToAssets;
  requestFormGroup: FormGroup;
  l1ApproveFormGroup: FormGroup;
  moreInfoFormGroup: FormGroup;
  rejectFormGroup: FormGroup;
  moreInfoL2FormGroup: FormGroup;
  preRfqFormGroup: FormGroup;
  rfqFormGroup: FormGroup;
  rejectL2FormGroup: FormGroup;
  poFormGroup: FormGroup;
  orderPlacedFormGroup: FormGroup;
  orderReceivedFormGroup: FormGroup;
  replyL1FormGroup: FormGroup;
  replyL2FormGroup: FormGroup;
  pushToAssetsFormGroup: FormGroup;
  requestList: any;
  displayedColumns: string[] = ['Name', 'Quantity', 'Req Date', 'Expected Date', 'comments', 'User Name', 'Action'];
  displayedPreRfqColumns: string[] = ['Name', 'Quantity', 'Bill To Customer', 'Req Date', 'Expected Date', 'L1 Approval Date','L1 Approved By', 'User Name', 'Action'];
  displayedRfqColumns: string[] = ['Name', 'Quantity', 'Bill To Customer', 'Req Date', 'Expected Date', 'L1 Approval Date', 'Pre RFQ Approval Date', 'L1 Approved By','User Name', 'Action'];
  displayeL1ApprovalColumns: string[] = ['Name', 'Quantity', 'Bill To Customer', 'Req Date', 'Expected Date', 'Pre RFQ Expected Date', 'unit_price', 'total', 'L1 Approval Date', 'Pre RFQ Approval Date', 'RFQ Approval Date', 'comments','L1 Approved By', 'User Name', 'Action'];
  displayedPOColumns: string[] = ['Name', 'Quantity', 'Bill To Customer', 'Req Date', 'Expected Date', 'Pre RFQ Expected Date', 'unit_price', 'total', 'L1 Approval Date', 'Pre RFQ Approval Date', 'RFQ Approval Date', 'L2 Approval Date', 'L1 Approved By','L2 Approved By', 'User Name', 'Action'];
  displayedOrderPlacedColumns: string[] = ['Name', 'Quantity', 'Bill To Customer', 'Req Date', 'Expected Date', 'Pre RFQ Expected Date', 'unit_price', 'total', 'L1 Approval Date', 'Pre RFQ Approval Date', 'RFQ Approval Date', 'L2 Approval Date', 'PO Approval Date', 'L1 Approved By','L2 Approved By', 'User Name', 'Action'];
  displayedOrderReceivedColumns: string[] = ['Name', 'Quantity', 'Bill To Customer', 'Req Date', 'Expected Date', 'Pre RFQ Expected Date', 'unit_price', 'total', 'L1 Approval Date', 'Pre RFQ Approval Date', 'RFQ Approval Date', 'L2 Approval Date', 'PO Approval Date', 'Place the Order Approval Date', 'L1 Approved By', 'L2 Approved By', 'User Name', 'Action'];
  displayedPushToAssetsColumns: string[] = ['Name', 'Quantity', 'Bill To Customer', 'Req Date', 'Expected Date', 'Pre RFQ Expected Date', 'unit_price', 'total', 'L1 Approval Date', 'Pre RFQ Approval Date', 'RFQ Approval Date', 'L2 Approval Date', 'PO Approval Date', 'Place the Order Approval Date', 'Waiting to Receive Approval Date', 'L1 Approved By', 'L2 Approved By', 'User Name', 'Action'];
  displayedRejectedColumns: string[] = ['No', 'Name', 'Quantity', 'Req Date', 'Expected Date', 'User Name', 'Comment'];
  displayedMoreInfoColumns: string[] = ['No', 'Name', 'Quantity', 'Req Date', 'Expected Date', 'User Name', 'Comment', 'reply Comment'];
  displayedMoreInfoL2Columns: string[] = ['Name', 'Quantity', 'Bill To Customer', 'Req Date', 'Expected Date', 'Pre RFQ Expected Date', 'unit_price', 'total', 'L1 Approval Date', 'Pre RFQ Approval Date', 'RFQ Approval Date', 'comments','L1 Approved By', 'User Name', 'reply Comment'];
  dataSource: any;
  rejectDataSource: any;
  moreInfoDataSource: any;
  moreInfoL2DataSource: any;
  thirdStageDataSource: any;
  rfqDataSource: any;
  l2ApprovalDataSource: any;
  poDataSource: any;
  orderPlacedDataSource: any;
  pushToAssetsDataSource: any;
  // @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  // @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild('paginator3') paginator3: MatPaginator;
  @ViewChild('paginator4') paginator4: MatPaginator;
  @ViewChild('paginator5') paginator5: MatPaginator;
  @ViewChild('paginator6') paginator6: MatPaginator;
  @ViewChild('paginator7') paginator7: MatPaginator;
  @ViewChild('paginator8') paginator8: MatPaginator;
  @ViewChild('paginator9') paginator9: MatPaginator;
  @ViewChild('paginator10') paginator10: MatPaginator;
  @ViewChild('paginator11') paginator11: MatPaginator;
  requestRejectList: any;
  rejectElement: any;
  requestMoreInfoList: any;
  moreInfoElement: any;
  preRfqRequestList: any;
  stage4Element: any;
  rfqRequestList: any;
  rfqElement: any;
  l2ApprovalRequestList: any;
  poRequestList: any;
  poElement: any;
  orderPlacedRequestList: any;
  orderPlacedElement: any;
  pushToAssetsRequestList: any;
  stage2Element: any;
  requestMoreInfoL2List: any;
  moreInfoL2Element: any;
  stage5ReplyElement: any;
  l1ApproveElement: any;
  orderReceivedRequestList: any;
  orderReceivedDataSource: any;
  orderReceivedElement: any;
  pushToAssetsElement: any;
  //today's date
  todayDate = new Date();
  asset: boolean ;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClientService,
    private ss: SingletonService,
    private user: UserService
  ) {
    this.requestFormGroup = this.formBuilder.group({
      'req_name': ['', Validators.required,],
      'req_quantity': ['', Validators.required],
      // 'bill_to_customer': ['', Validators.required],
      'expected_date': ['', Validators.required],
      'req_comment': ['', Validators.required]
    });
    this.l1ApproveFormGroup = this.formBuilder.group({
      'bill_to_customer': ['', Validators.required]
    });
    this.moreInfoFormGroup = this.formBuilder.group({
      'moreinfo_comment': ['', Validators.required,]
    });
    this.moreInfoL2FormGroup = this.formBuilder.group({
      'moreinfo_comment': ['', Validators.required,]
    });
    this.rejectFormGroup = this.formBuilder.group({
      'reject_comment': ['', Validators.required,]
    });
    this.rejectL2FormGroup = this.formBuilder.group({
      'reject_comment': ['', Validators.required,]
    });
    this.replyL1FormGroup = this.formBuilder.group({
      'req_name': ['', Validators.required,],
      'req_quantity': ['', Validators.required],
      'expected_date': ['', Validators.required],
      'reply_comment': ['', Validators.required]
    });
    this.replyL2FormGroup = this.formBuilder.group({
      'unit_price': ['', Validators.required],
      'expected_date': ['', Validators.required],
      // 'rfq_comment': ['', Validators.required],
      'reply_comment': ['', Validators.required]
    });
    this.preRfqFormGroup = this.formBuilder.group({
      'prerfq_comment': ['', Validators.required,]
    });
    this.rfqFormGroup = this.formBuilder.group({
      'unit_price': ['', Validators.required],
      'expected_date': ['', Validators.required],
      'rfq_comment': ['', Validators.required,]
    });
    this.poFormGroup = this.formBuilder.group({
      'po_comment': ['', Validators.required]
    });
    this.orderPlacedFormGroup = this.formBuilder.group({
      'orderplaced_comment': ['', Validators.required]
    });
    this.orderReceivedFormGroup = this.formBuilder.group({
      'orderreceived_comment': ['', Validators.required]
    });
    this.pushToAssetsFormGroup = this.formBuilder.group({
      'is_travel_reimbursement':['',Validators.required],
      'pushtoassets_single':['', Validators.required],
      'pushtoassets_comment': ['']
    });
  }

  changeComboo(value){
    this.asset = value;
    console.log(value);
    
  }

  addRequestModal() {
    this.NgFormRequest.resetForm();
    this.requestModal.open();
    console.log(this.user.getUserId());
  }
  requestRejectModal() {
    this.rejectedModal.open();
  }
  requestL2RejectModal() {
    this.rejectedL2CommentModal.open();
  }

  requestMoreInfoModal() {
    this.moreInfoModal.open();
  }

  requestMoreInfoL2Modal() {
    this.moreInfoL2Modal.open();
  }

  preRfqStageOpen() {
    this.preRfqStageModal.open();
  }
  rfqStageOpen() {
    this.rfqStageModal.open();
  }

  l2ApprovalStageOpen() {
    this.l2ApprovalStageModal.open();
  }
  poStageOpen() {
    this.poStageModal.open();
  }
  orderPlacedStageOpen() {
    this.orderPlacedStageModal.open();
  }
  orderReceivedStageOpen() {
    this.orderReceviedStageModal.open();
  }
  pushToAssetsStageOpen() {
    this.pushToAssetsstageModal.open();
  }

  addrequestSubmit(value) {

    console.log(value);
    value.user_id = this.user.getUserId();
    console.log(this.user.getUserId);

    value.stage_id = 2;
    if (this.requestFormGroup.valid) {
      this.httpClient.request('post', this.ss.addRequest, '', value).subscribe((res: any) => {
        if (res.status === 200) {
          if (res.error.text == "Request added sucessfully") {
            this.ss.statusMessage.showStatusMessage(true, 'Request added sucessfully');
            this.NgFormRequest.resetForm();
            this.getRequestList();
            this.requestModal.close();
          }
          else if (res.error.text == "Request already exist") {
            this.ss.statusMessage.showStatusMessage(false, 'Request already exist', 3000, 'exist');
            this.getRequestList();
          }

        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }

  }

  getRequestList() {
    this.httpClient.request('get', this.ss.getRequests).subscribe((res: any) => {
      if (res.status === 200) {
        this.requestList = res.body;
        this.dataSource = new MatTableDataSource(this.requestList);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  rejectList() {
    this.httpClient.request('get', this.ss.getRejectRequests).subscribe((res: any) => {
      if (res.status === 200) {
        this.requestRejectList = res.body;
        console.log(this.requestRejectList);

        this.rejectDataSource = new MatTableDataSource(this.requestRejectList);
        this.rejectDataSource.paginator = this.paginator2;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getMoreInfoList() {
    this.httpClient.request('get', this.ss.getMoreInfoRequests).subscribe((res: any) => {
      if (res.status === 200) {
        this.requestMoreInfoList = res.body;
        console.log(this.requestMoreInfoList);

        this.moreInfoDataSource = new MatTableDataSource(this.requestMoreInfoList);
        this.moreInfoDataSource.paginator = this.paginator3;

      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getMoreInfoL2List() {
    this.httpClient.request('get', this.ss.getMoreInfoL2Requests).subscribe((res: any) => {
      if (res.status === 200) {
        this.requestMoreInfoL2List = res.body;
        console.log(this.requestMoreInfoL2List);

        this.moreInfoL2DataSource = new MatTableDataSource(this.requestMoreInfoL2List);
        this.moreInfoL2DataSource.paginator = this.paginator4;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getPreRfqList() {
    this.httpClient.request('get', this.ss.getPreRfqRequests).subscribe((res: any) => {
      if (res.status === 200) {
        this.preRfqRequestList = res.body;
        console.log(this.preRfqRequestList);
        this.thirdStageDataSource = new MatTableDataSource(this.preRfqRequestList);
        this.thirdStageDataSource.paginator = this.paginator5;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getRfqList() {
    this.httpClient.request('get', this.ss.getRfqRequests).subscribe((res: any) => {
      if (res.status === 200) {
        this.rfqRequestList = res.body;
        this.rfqDataSource = new MatTableDataSource(this.rfqRequestList);
        this.rfqDataSource.paginator = this.paginator6;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getL2ApprovalList() {
    this.httpClient.request('get', this.ss.getL2ApprovalRequests).subscribe((res: any) => {
      if (res.status === 200) {
        this.l2ApprovalRequestList = res.body;
        this.l2ApprovalDataSource = new MatTableDataSource(this.l2ApprovalRequestList);
        this.l2ApprovalDataSource.paginator = this.paginator7;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getPOList() {
    this.httpClient.request('get', this.ss.getPORequests).subscribe((res: any) => {
      if (res.status === 200) {
        this.poRequestList = res.body;
        this.poDataSource = new MatTableDataSource(this.poRequestList);
        this.poDataSource.paginator = this.paginator8;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getOrderPlacedList() {
    this.httpClient.request('get', this.ss.getOrderPlacedRequests).subscribe((res: any) => {
      if (res.status === 200) {
        this.orderPlacedRequestList = res.body;
        this.orderPlacedDataSource = new MatTableDataSource(this.orderPlacedRequestList);
        this.orderPlacedDataSource.paginator = this.paginator9;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getOrderReceivedList() {
    this.httpClient.request('get', this.ss.getOrderReceivedRequests).subscribe((res: any) => {
      if (res.status === 200) {
        this.orderReceivedRequestList = res.body;
        this.orderReceivedDataSource = new MatTableDataSource(this.orderReceivedRequestList);
        this.orderReceivedDataSource.paginator = this.paginator11;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getPushToAssetsList() {
    this.httpClient.request('get', this.ss.getPushToAssetsRequests).subscribe((res: any) => {
      if (res.status === 200) {
        this.pushToAssetsRequestList = res.body;
        this.pushToAssetsDataSource = new MatTableDataSource(this.pushToAssetsRequestList);
        this.pushToAssetsDataSource.paginator = this.paginator10;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  l1StageOpen() {
    this.l1StageModal.open();
    this.getRequestList();
  }

  moveToStage2(element) {
    element.user_id = this.user.getUserId();
    element.stage_id = 2;
    this.NgFormL1Reply.resetForm();
    this.move2Stage2CommentModal.open();
    this.replyL1FormGroup.controls['req_name'].setValue(element.name);
    this.replyL1FormGroup.controls['req_quantity'].setValue(element.quantity);
    this.replyL1FormGroup.controls['expected_date'].setValue(element.expected_date);
    this.stage2Element = element;
  }

  replyL1Submit(value) {
    console.log(value);
    console.log(this.stage2Element);
    var element = this.stage2Element;
    element.req_name = value.req_name;
    element.req_quantity = value.req_quantity;
    element.expected_date = value.expected_date;
    element.reply_comment = value.reply_comment;
    console.log(element);
    if (this.replyL1FormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage2, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Reply was added and successfully moved to L1 Approval Stage');
          this.getRequestList();
          this.getMoreInfoList();
          this.move2Stage2CommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getRequestList();
          this.getMoreInfoList();
          this.move2Stage2CommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }
  }

  moveToStage5WithMoreInfo(element) {
    element.user_id = this.user.getUserId();
    element.stage_id = 5;
    this.NgFormL2Reply.resetForm();
    this.replyL2FormGroup.controls['unit_price'].setValue(element.unit_price);
    this.replyL2FormGroup.controls['expected_date'].setValue(element.prerfq_expecteddate);
    // this.replyL1FormGroup.controls['reply_comment'].setValue(element.quantity);
    this.move2Stage5ReplyCommentModal.open();
    this.stage5ReplyElement = element;
    console.log(element);
  }

  replyL2Submit(value) {
    console.log(value);
    console.log(this.stage5ReplyElement);
    var element = this.stage5ReplyElement;
    element.unit_price1 = value.unit_price;
    element.expected_date1 = value.expected_date;
    element.reply_comment = value.reply_comment;
    console.log(element);
    if (this.replyL2FormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage5WithReply, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Reply was added and successfully moved to L2 Approval Stage');
          this.getL2ApprovalList();
          this.getMoreInfoL2List();
          this.move2Stage5ReplyCommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getL2ApprovalList();
          this.getMoreInfoL2List();
          this.move2Stage5ReplyCommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }
  }

  moveToStage3(element) {
    console.log(element);
    element.user_id = this.user.getUserId();
    element.stage_id = 3;
    this.NgFormL1Approve.resetForm();
    this.l1StageApproveModal.open();
    this.l1ApproveElement = element;
  }

  l1ApproveSubmit(value) {
    var element = this.l1ApproveElement;
    element.bill_to_customer = value.bill_to_customer;
    if (this.l1ApproveFormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage3, '', element).subscribe((res: any) => {

        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Request Approved Sucessfully and moved to PreRFQ stage');
          this.getRequestList();
          this.getPreRfqList();
          this.l1StageApproveModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getRequestList();
          this.getPreRfqList();
          this.l1StageApproveModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }
  }

  moveToStage4(element) {
    element.user_id = this.user.getUserId();
    element.stage_id = 4;
    this.NgFormPreRfq.resetForm();
    this.move2Stage4CommentModal.open();
    this.stage4Element = element;

  }

  preRfqSubmit(value) {
    console.log(value);
    console.log(this.stage4Element);
    var element = this.stage4Element;
    element.prerfq_comment = value.prerfq_comment;
    console.log(element);
    if (this.preRfqFormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage4, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Request was successfully moved to RFQ Stage');
          this.getPreRfqList();
          this.getRfqList();
          this.move2Stage4CommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getPreRfqList();
          this.getRfqList();
          this.move2Stage4CommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }
  }

  moveToStage5(element) {
    element.user_id = this.user.getUserId();
    element.stage_id = 5;
    this.NgFormRfq.resetForm();
    this.move2Stage5CommentModal.open();
    this.rfqElement = element;

  }

  rfqSubmit(value) {
    console.log(value);
    console.log(this.stage4Element);
    var element = this.rfqElement;
    element.unit_price = value.unit_price;
    element.expected_date = value.expected_date;
    element.rfq_comment = value.rfq_comment;
    console.log(element);
    if (this.rfqFormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage5, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Request was successfully moved to L2Approval');
          this.getRfqList();
          this.getL2ApprovalList();
          this.move2Stage5CommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getRfqList();
          this.getL2ApprovalList();
          this.move2Stage5CommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }
  }

  moveToStage6(element) {
    console.log(element);
    element.user_id = this.user.getUserId();
    element.stage_id = 6;

    this.httpClient.request('post', this.ss.moveToStage6, '', element).subscribe((res: any) => {
      if (res.error.text === 'stage moved sucessfully') {
        this.ss.statusMessage.showStatusMessage(true, 'The Request Approved Sucessfully and moved to PO stage');
        this.getL2ApprovalList();
        this.getPOList();
      }
      else if (res.error.text === 'stage was already approved') {
        this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
        this.getL2ApprovalList();
        this.getPOList();
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  moveToStage7(element) {
    element.user_id = this.user.getUserId();
    element.stage_id = 7;
    this.NgFormPO.resetForm();
    this.move2Stage7CommentModal.open();
    this.poElement = element;

  }

  poSubmit(value) {
    console.log(value);
    console.log(this.poElement);
    var element = this.poElement;
    element.po_comment = value.po_comment;
    console.log(element);
    if (this.poFormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage7, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Request was successfully moved to Order Placed Stage');
          this.getPOList();
          this.getOrderPlacedList();
          this.move2Stage7CommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getPOList();
          this.getOrderPlacedList();
          this.move2Stage7CommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }
  }


  moveToStage8(element) {
    element.user_id = this.user.getUserId();
    element.stage_id = 8;
    this.NgFormOrderPlaced.resetForm();
    this.move2Stage8CommentModal.open();
    this.orderPlacedElement = element;

  }

  orderPlacedSubmit(value) {
    console.log(value);
    console.log(this.orderPlacedElement);
    var element = this.orderPlacedElement;
    element.orderplaced_comment = value.orderplaced_comment;
    console.log(element);
    if (this.orderPlacedFormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage8, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Request was successfully moved to Waiting to Receive Stage');
          this.getOrderPlacedList();
          this.getOrderReceivedList();
          this.move2Stage8CommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getOrderPlacedList();
          this.getOrderReceivedList();
          this.move2Stage8CommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }
  }
  moveToStage9(element) {
    element.user_id = this.user.getUserId();
    element.stage_id = 9;
    this.NgFormOrderReceived.resetForm();
    this.move2Stage9CommentModal.open();
    this.orderReceivedElement = element;

  }

  orderReceivedSubmit(value) {
    console.log(value);
    console.log(this.orderReceivedElement);
    var element = this.orderReceivedElement;
    element.orderreceived_comment = value.orderreceived_comment;
    console.log(element);
    if (this.orderReceivedFormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage9, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Request was successfully moved to Push to Assets Stage');
          this.getOrderReceivedList();
          this.getPushToAssetsList();
          this.move2Stage9CommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getOrderReceivedList();
          this.getPushToAssetsList();
          this.move2Stage9CommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }

  }

  moveToProducts(element) {
    element.user_id = this.user.getUserId();
    element.stage_id = 9;
    this.NgFormpushToAssets.resetForm();
    this.move2AssetsCommentModal.open();
    this.pushToAssetsElement = element;

  }

  pushToAssetsSubmit(value) {
    console.log(value);
    console.log(this.pushToAssetsElement);
    var element = this.pushToAssetsElement;
    if(value.is_travel_reimbursement === 'true'){
      element.category = 2;
    }
    else{
      element.category = 1;
    }
    element.pushtoassets_comment = value.pushtoassets_comment;
    console.log(element);
    if(element.quantity > 1 && element.category === 1){
      this.pushToAssetsFormGroup.controls['pushtoassets_single'].setValidators([Validators.required]);
    }
    else{
      this.pushToAssetsFormGroup.controls['pushtoassets_single'].clearValidators();
      this.pushToAssetsFormGroup.controls['pushtoassets_single'].updateValueAndValidity()
    }
    if(value.pushtoassets_single === 'single'){
    if (this.pushToAssetsFormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToProductsSingle, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'Request was successfully moved to Assets');
          this.getPushToAssetsList();
          this.move2AssetsCommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getPushToAssetsList();
          this.move2AssetsCommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
      }
    }
    else if(value.pushtoassets_single == null && element.category === 2){
      if (this.pushToAssetsFormGroup.valid) {
        this.httpClient.request('post', this.ss.moveToProductsSingleTravel, '', element).subscribe((res: any) => {
          if (res.error.text === 'stage moved sucessfully') {
            this.ss.statusMessage.showStatusMessage(true, 'Request was successfully moved to Assets');
            this.getPushToAssetsList();
            this.move2AssetsCommentModal.close();
          }
          else if (res.error.text === 'stage was already approved') {
            this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
            this.getPushToAssetsList();
            this.move2AssetsCommentModal.close();
          }
          else {
            this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
          }
        });
        }
      }
      else{
        if (this.pushToAssetsFormGroup.valid && element.category === 1) {
          this.httpClient.request('post', this.ss.moveToProducts, '', element).subscribe((res: any) => {
            if (res.error.text === 'stage moved sucessfully') {
              this.ss.statusMessage.showStatusMessage(true, 'Request was successfully moved to Assets');
              this.getPushToAssetsList();
              this.move2AssetsCommentModal.close();
            }
            else if (res.error.text === 'stage was already approved') {
              this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
              this.getPushToAssetsList();
              this.move2AssetsCommentModal.close();
            }
            else {
              this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
            }
          });
          }

      }
    
  }

  stage2MoreInfo(element) {
    this.moreInfoCommentModal.open();
    this.NgFormMoreInfo.resetForm();
    this.moreInfoElement = element;
  }

  moreInfoSubmit(value) {
    console.log(value);
    console.log(this.moreInfoElement);
    var element = this.moreInfoElement;
    element.moreinfo_comment = value.moreinfo_comment;
    console.log(element);
    if (this.moreInfoFormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage1ForMoreInfo, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Request was moved to New stage for more info');
          this.getRequestList();
          this.getMoreInfoList();
          this.moreInfoCommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getRequestList();
          this.getMoreInfoList();
          this.moreInfoCommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }
  }

  moveToStage4ForInfo(element) {
    this.moreInfoL2CommentModal.open();
    this.NgFormL2MoreInfo.resetForm();
    this.moreInfoL2Element = element;
  }

  moreInfoL2Submit(value) {
    console.log(value);
    console.log(this.moreInfoL2Element);
    var element = this.moreInfoL2Element;
    element.user_id = this.user.getUserId();
    element.moreinfo_comment = value.moreinfo_comment;
    console.log(element);
    if (this.moreInfoL2FormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage4ForMoreInfo, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Request was moved to RFQ Stage for more info');
          this.getL2ApprovalList();
          this.getMoreInfoL2List();
          this.moreInfoL2CommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getL2ApprovalList();
          this.getMoreInfoL2List();
          this.moreInfoL2CommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }
  }

  stage2Reject(element) {
    this.rejectedCommentModal.open();
    this.NgFormReject.resetForm();
    this.rejectElement = element;
  }

  rejectSubmit(value) {
    console.log(value);
    console.log(this.rejectElement);
    var element = this.rejectElement;
    element.user_id = this.user.getUserId();
    element.reject_comment = value.reject_comment;
    console.log(element);
    if (this.rejectFormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage1ForReject, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Request was Rejected');
          this.getRequestList();
          this.rejectList();
          this.rejectedCommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getRequestList();
          this.rejectList();
          this.rejectedCommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }
  }

  stage5Reject(element) {
    this.rejectedL2CommentModal.open();
    this.NgFormReject.resetForm();
    this.rejectElement = element;
  }

  rejectL2Submit(value) {
    console.log(value);
    console.log(this.rejectElement);
    var element = this.rejectElement;
    element.user_id = this.user.getUserId();
    element.reject_comment = value.reject_comment;
    console.log(element);
    if (this.rejectL2FormGroup.valid) {
      this.httpClient.request('post', this.ss.moveToStage1ForL2Reject, '', element).subscribe((res: any) => {
        if (res.error.text === 'stage moved sucessfully') {
          this.ss.statusMessage.showStatusMessage(true, 'The Request was Rejected');
          this.getL2ApprovalList();
          this.rejectList();
          this.rejectedL2CommentModal.close();
        }
        else if (res.error.text === 'stage was already approved') {
          this.ss.statusMessage.showStatusMessage(false, 'This Request was already Aproved');
          this.getL2ApprovalList();
          this.rejectList();
          this.rejectedL2CommentModal.close();
        }
        else {
          this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
        }
      });
    }
  }

  ngOnInit() {
    console.log(this.user.getUserId());
    this.getRequestList();
    this.rejectList();
    this.getMoreInfoList();
    this.getMoreInfoL2List();
    this.getPreRfqList();
    this.getRfqList();
    this.getL2ApprovalList();
    this.getPOList();
    this.getOrderPlacedList();
    this.getOrderReceivedList();
    this.getPushToAssetsList();
  }

}
