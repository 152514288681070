import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit, HostListener, HostBinding, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'dbd-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('toggleMenu', [
      state(
        'true',
        style({
          'width': '225px'
        })
      ),
      state(
        'false',
        style({
          'width': '100px'
        })
      ),
      transition('*<=>*', [animate(
        '200ms'
      )])
    ])
  ]
})
export class SidebarComponent implements OnInit {

  overView = '../../../assets/images/Overview.png';
  addEmployee = '../../../assets/images/Add Employee.png';
  employeeList = '../../../assets/images/Employee List.png';
  project = '../../../assets/images/Projects.png';
  mapping = '../../../assets/images/MAPPING.png';
  report = '../../../assets/images/Report.png';

  static selectedIndex: number;
  constructor(
    private ss: SingletonService
  ) { }

  items:any = [
  {text:'M R', link:'/dashboard', icon:'dashboard', selectedIndex: 0},
  {text:'Assets', link:'/products', icon:'web_asset', selectedIndex: 1},
  {text:'Reports', link:'/reports', icon:'graphic_eq', selectedIndex: 1}
]

  isMenuOpen: boolean = false;

  @Input() selectedIndex: number = 0;
  select(index: number) {
      this.selectedIndex = index;
  }

  ngOnInit() {    
    this.ss.isMenuOpenSubject.next(this.isMenuOpen);
     
      this.ss.setactive.subscribe(res => {
        this.selectedIndex = res
      }, err => {
        console.log(err);
      });
  }

  // @HostListener('click', ['$event', '$event.target'])
  onClick(e, target) {
    this.isMenuOpen = !this.isMenuOpen;
    this.ss.isMenuOpenSubject.next(this.isMenuOpen);
  }

  @HostBinding('@toggleMenu') get toggleMenu() {
    return this.isMenuOpen;
  }
  // @HostBinding('class.menu-open') get open() { return this.isMenuOpen; }
}
