import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { ModalPopupComponent } from 'src/app/components/modal-popup/modal-popup.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  displayedColumns: string[] = ['No','product_id','Name','Date','asset_id','Action'];
  displayedColumnsReimbursementList: string[] = ['product_id','Name','Date','Action'];
  displayProductHistoryColumns: string[] = ['Name', 'Quantity', 'Req Date','Expected Date','Pre RFQ Expected Date','unit_price','total','L1 Approval Date','Pre RFQ Approval Date','RFQ Approval Date','L2 Approval Date','PO Approval Date','Place the Order Approval Date','Waiting to Receive Approval Date','Push To Assets Approval Date','L1 Approved By','L2 Approved By','User Name'];
  dataSource:any;
  dataSource1:any;
  @ViewChild('productHistoryStageModal') productHistoryStageModal:ModalPopupComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator1') paginator1: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  productList: any;
  productHistory: any;
  productHistoryDataSource: any;
  productInfo: any;
  reimbursementList: any;
  constructor(
    private httpClient: HttpClientService,
    private ss: SingletonService
  ) { }

  getProductList(){
    this.httpClient.request('get', this.ss.getProducts).subscribe((res: any) => {
      if (res.status === 200) {
        this.productList = res.body;
        this.dataSource = new MatTableDataSource(this.productList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getReimbursementList(){
    this.httpClient.request('get', this.ss.getReimbursement).subscribe((res: any) => {
      if (res.status === 200) {
        this.reimbursementList = res.body;
        this.dataSource1 = new MatTableDataSource(this.reimbursementList);
        this.dataSource1.paginator = this.paginator2;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
  }

  getInfo(element){
    console.log(element);
    this.productInfo = element;
    this.productHistoryStageModal.open();

    this.httpClient.request('get', this.ss.getProductHistory,'req_id='+element.req_id).subscribe((res: any) => {
      if (res.status === 200) {
        this.productHistory = res.body;
        console.log(this.productHistory);
        this.productHistoryDataSource = new MatTableDataSource(this.productHistory);
        this.productHistoryDataSource.paginator = this.paginator1;
      }
      else {
        this.ss.statusMessage.showStatusMessage(false, "Something went wrong");
      }
    });
    
    
  }

  ngOnInit() {
    this.getProductList();
    this.getReimbursementList();
  }

}
