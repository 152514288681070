import { HttpClientService } from 'src/app/services/http-client.service';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { SingletonService } from './../../services/singleton.service';
import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

interface IBreadcrumb {
  label: string;
  noclick?: boolean;
  queryParams?: Object,
  url: string;
}

@Component({
  selector: 'dbd-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  public dashboardlink;
  public showBreadCrumb: boolean;
  public breadcrumbs: IBreadcrumb[];
  public params;
  public childLabel;
  public returnUrl = "";
  spsysType: any;
  constructor(
    private httpClient: HttpClientService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ss: SingletonService,
  ) {
    this.breadcrumbs = [];

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      //set breadcrumbs      
      this.ss.breadCrumbSubject.next();
      this.getBreadcrumbs();
    });

    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(event => {
      //set breadcrumbs     
      this.ss.breadCrumbMap = [];
    });
    

  }


  isEmptyObject(obj) {
    if (Object.keys(obj).length === 0 && obj.constructor === Object) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.getsparcleSysType();
    this.dashboardlink = '/dashboard';

    //subscribe to the NavigationEnd event
    this.ss.breadCrumbSubject.subscribe(() => {
      this.getBreadcrumbs();
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      //set breadcrumbs      
      this.ss.breadCrumbSubject.next();
      this.getBreadcrumbs();
    });

    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(event => {
      //set breadcrumbs     
      this.ss.breadCrumbMap = [];
    });




    //this.ss.breadCrumbSubject.next();
  }

  getsparcleSysType() {
      // this.httpClient.noLoader(true).request('get', this.ss.getSparcleSysType).subscribe((res: HttpResponse<any>) => {
      //     let configPrpts = res.body;
      //     this.spsysType = configPrpts.type
      // });

}
  getBreadcrumbs(child?: ActivatedRoute) {

    // declare children variable of type array of activated routes
    var children: ActivatedRoute[];
    //get the child routes
    if (child) {
      children = child.children;
      //console.log(route.children);
    } else {
      children = this.activatedRoute.children;
      this.breadcrumbs = [];
      let segment = this.activatedRoute.snapshot.url.map(segment => segment.path)[0];
      this.returnUrl = '/' + (segment ? segment : '');
    }
    //iterate over each children
    for (let child of children) {
      //console.log('bc-----'+child.snapshot.data['breadcrumb'] + '---'+child.snapshot.url.map(segment => segment.path)[0]);

      // deal with the params in this route segment
      let params = child.snapshot.params;
      // path string for the current route segment
      let path = child.snapshot.url.map(segment => segment.path)[0];
      if (path && path !== "dashboard") {
        this.showBreadCrumb = true;

        let routeBreadcrumbData = child.snapshot.data['breadcrumb'];

        if (routeBreadcrumbData) {
          // console.log(this.returnUrl)
          this.returnUrl += '/' + path;
          // if there are NO params in the current route segment
          if (this.isEmptyObject(params)) {
            //console.log(path+'---------' + this.getUrlForCurrentRoute(path));


            let noclick: boolean;
            // if the route has data noclick and it is equal to true
            noclick = (child.snapshot.data['noclick']) ? true : false;

            // if we need to get the label of the breadcrumb from one of the query params we check in the breadcrumb data which will be an object like eg: {queryParamKey: 'edge_node_id', addQueryParamInUrl:true}
            if (routeBreadcrumbData instanceof Object) {
              let queryParams = {};
              let label;
              if (routeBreadcrumbData.addQueryParamInUrl) {
                if (routeBreadcrumbData.queryParamKey === 'all') {
                  queryParams = child.snapshot.queryParams;
                } else {
                  if (routeBreadcrumbData.queryParamKey instanceof Array) {
                    routeBreadcrumbData.queryParamKey.forEach(key => {
                      queryParams[key] = child.snapshot.queryParamMap.get(key);
                    });
                  } else {
                    queryParams[routeBreadcrumbData.queryParamKey] = child.snapshot.queryParamMap.get(routeBreadcrumbData.queryParamKey);
                  }
                }
              }
              if (path && path === "edge-node") {
                label = routeBreadcrumbData.label ? routeBreadcrumbData.label : 'Cube - '+child.snapshot.queryParamMap.get(routeBreadcrumbData.queryParamKey);
              } else if (path && path === "edge-node-details") {
                label = routeBreadcrumbData.label ? routeBreadcrumbData.label : 'Details';
              } else if (path && path === "gpu-details") {
                label = routeBreadcrumbData.label ? routeBreadcrumbData.label : 'GPU - '+child.snapshot.queryParamMap.get(routeBreadcrumbData.queryParamKey);
              } else if (path && path === "cpu-details") {
                label = this.spsysType
              } else {
                label = routeBreadcrumbData.label ? routeBreadcrumbData.label : child.snapshot.queryParamMap.get(routeBreadcrumbData.queryParamKey);
              }
                this.breadcrumbs.push({
                label: label,
                noclick: noclick,
                url: this.returnUrl,
                queryParams: queryParams
              });
              
            } else {
                this.breadcrumbs.push({
                  label: child.snapshot.data['breadcrumb'],
                  noclick: noclick,
                  url: this.returnUrl
                });
              
            }

          } else {
            // when there ARE params in the current route segment

            let noclick: boolean;
            // if the route has data noclick and it is equal to true
            noclick = (child.snapshot.data['noclick']) ? true : false;
            this.breadcrumbs.push({
              label: child.snapshot.data['breadcrumb'],
              noclick: noclick,
              url: this.returnUrl
            });
            for (let childParam in child.snapshot.params) {
              this.returnUrl += '/' + child.snapshot.params[childParam];
              if (this.ss.breadCrumbMap[childParam] || child.snapshot.params[childParam]) {
                this.breadcrumbs.push({
                  label: this.ss.breadCrumbMap[childParam] || child.snapshot.params[childParam],
                  noclick: this.ss.breadCrumbMap[childParam + '_noclick'],
                  queryParams: this.ss.breadCrumbMap[childParam + '_queryParams'],
                  url: this.returnUrl
                });
              }
            }
          }
        } else {
          if (!this.isEmptyObject(params)) {
            for (let childParam in child.snapshot.params) {
              this.returnUrl += '/' + child.snapshot.params[childParam];
              if (this.ss.breadCrumbMap[childParam] || child.snapshot.params[childParam]) {
                this.breadcrumbs.push({
                  label: this.ss.breadCrumbMap[childParam] || child.snapshot.params[childParam],
                  noclick: this.ss.breadCrumbMap[childParam + '_noclick'],
                  queryParams: this.ss.breadCrumbMap[childParam + '_queryParams'],
                  url: this.returnUrl
                });
              }
            }
          }
        }
      } else if (path && path === "dashboard") {
        // hide breadcrumbs when it is dashboard     
        this.showBreadCrumb = false;
      }

      //recursing when there are children in the route
      if (child.snapshot.children.length > 0) {
        this.getBreadcrumbs(child);
      }

    }
  }

}
