import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDownloadDirective } from './file-download.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FileDownloadDirective],
  exports:[FileDownloadDirective]
})
export class FileDownloadModule { }
