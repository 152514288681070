import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';
import { SingletonService } from './services/singleton.service';
import { Component, ViewChild, HostBinding } from '@angular/core';
import * as d3 from 'd3'; 
import { LoaderComponent } from './components/loader/loader.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { StatusMessageComponent } from './components/status-message/status-message.component';
import { Router, ActivatedRoute } from '@angular/router';
import {MatInputModule} from '@angular/material/input';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  router: string;
  url: string;
  constructor(
    private ss: SingletonService,
    private _router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.url.subscribe(activeUrl =>{
      this.url= window.location.pathname;
      console.log(this.url);
    });
  }

  @ViewChild(LoaderComponent) loader;

  @ViewChild(ProgressBarComponent) progressBar;

  @ViewChild(StatusMessageComponent) statusMessage;

  @ViewChild(ModalPopupComponent) modal;

  title = 'Dashboard';

  isMenuVisible: boolean = true;

  isMenuOpen = false;

  onClick(e:Event,target){
    // this.ss.statusMessage.showStatusMessage(false,"No message");
    // this.ss.statusMessage.showStatusMessage(true,"YES message");
    // this.modal.open();
  }
 

  ngOnInit() {
    this.ss.isMenuOpenSubject.subscribe((value)=>{
      this.isMenuOpen = value;
    });
    
  }

  ngAfterViewInit(){
    this.ss.loader = this.loader;
    this.ss.progressBar = this.progressBar;
    this.ss.statusMessage = this.statusMessage;
  }

    
  @HostBinding('class.menu-open') get menuOpen() { return this.isMenuOpen; }
  @HostBinding('class.has-menu') get hasMenu() { return this.isMenuVisible; }

}

