import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { HttpClientService } from 'src/app/services/http-client.service';
import { SingletonService } from 'src/app/services/singleton.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dashboard-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  today:any = new Date();
  userList: any;
  @Input() username : any;
  constructor(
    private datepipe : DatePipe,
    private httpClient :HttpClientService,
    private ss : SingletonService,
    private user : UserService,
    private _router: Router
  ) { 
    // this.username = this.user.getUserName();
  }
  
  ngOnInit() {
    // this.today = this.datepipe.transform(this.today, 'dd-MMM-yyyy'); 
  }

  ngAfterViewInit(){
    this.username = this.user.getUserName();
  }


  logout(){
    this.user.logout();
  }

}
