import { Component, OnInit, ViewChild } from '@angular/core';
import { SingletonService } from 'src/app/services/singleton.service';
import { NgxDrpOptions } from 'ngx-mat-daterange-picker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  options: NgxDrpOptions;
  range: any;
  @ViewChild('pickerOne') pickerOne;
  rangeData: string;
  constructor(
    private ss: SingletonService,
    public datepipe: DatePipe
  ) { }

  ngOnInit() {
    const today = new Date();
    const todayTo = new Date();
    let todayFrom = new Date();
    todayFrom = new Date(todayFrom.setDate(todayFrom.getDate()));
    const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);
    this.ss.setupPresets();
    this.options = {
      presets: this.ss.presets,
      format: 'mediumDate',
      range: { fromDate: new Date(today.setDate(today.getDate() - 6)), toDate: new Date(todayTo.setDate(todayTo.getDate())) },
      applyLabel: 'Submit',
      // excludeWeekends:true,
      fromMinMax: { fromDate: fromMin, toDate: todayFrom },
      toMinMax: { fromDate: fromMin, toDate: todayFrom },
    };
  }

  reset() {
    const today = new Date();
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date();
    this.pickerOne.resetDates({ fromDate: currMonthStart, toDate: currMonthEnd });
  }

  updateRange(range: Range) {
    this.range = range;
    this.range.fromDate = this.datepipe.transform(this.range.fromDate, 'yyyy-MM-dd');
    this.range.toDate = this.datepipe.transform(this.range.toDate, 'yyyy-MM-dd');
    this.rangeData = JSON.stringify(this.range);
  }

  getDownloadExcel(){
    console.log('hello');
    
  }

}
